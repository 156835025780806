.collection-card-header {
  border-radius: 10px;
  padding-top: 100%;
  pointer-events: none;
  position: relative;

  &-inner {
    --bs-gutter-x: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
