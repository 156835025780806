@import 'theme/theme';
@import 'nft-page/mixins';

.accordion-item {
  color: var(--bs-silver);
  font-size: 14px;
  margin-top: 12px;

  .accordion-body-content {
    @include nft-page-content-padding();
    background-color: var(--bs-light-grey-bg);
    box-shadow: inset 0 4px 4px rgb(0 0 0 / 4%);
  }
}
