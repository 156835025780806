@import './src/theme/theme';

.hero-cover-image {
  background-color: var(--bs-blue-10);
  height: 100%;
  object-fit: cover;
  object-position: center;

  width: 100%;

  &.default {
    opacity: 0.15;
  }

  .dark-theme &.default {
    opacity: 0.5;
  }
}
