@import './src/theme/theme';

.my-asset-card-badge { 
  @include gradient-box($bg-color: var(--bs-light-grey-bg), $border-width: 1px, $border-radius: 30px);
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.35;
  margin-right: 4px;
  pointer-events: none;

  &-inner {
    align-items: center;
    display: flex;
    min-height: 1.7em;
    padding: 5px 10px;

    svg {
      height: auto;
      width: 18px;
    }
  }

  &-suffix {
    margin-left: 7px;
    opacity: 0.5;
  }

  &.badge-time-left {
    left: 0;
    right: auto;
  }

  &.badge-ended {
    @include gradient-box($bg-color: var(--bs-light-grey-bg), $start:var(--bs-blue-15),
    $end: var(--bs-silver) ,$border-width: 1px, $border-radius: 30px);
  }

  &.badge-top-bidder {
    @include gradient-box($bg-color: var(--bs-light-grey-bg), $start: var(--bs-green-light), 
    $end: var(--bs-green-dark),$border-width: 1px, $border-radius: 30px);
  }
}

.asset-card.small-on-mobile {
  .asset-card-badge-inner {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
      line-height: 1.35;
      padding: 4px 8px;
    }

    svg {
      @include media-breakpoint-down(lg) {
        width: 14px;
      }
    }
  }

  .asset-card-badge-suffix {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .asset-card-badge.badge-time-left {
    .asset-card-badge-label {
      display: inline;
    }
  }
}
