.cut-percentage-item {
  color: var(--bs-silver);
  font-size: 14px;

  &-title {
    color: var(--bs-dark-blue);
    font-weight: bold;
  }

  &-text {
    font-weight: bold;
  }
}
