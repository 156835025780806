@import 'src/theme/theme';

.unfollow-button {
  border-width: 2px;
  color: var(--bs-primary) !important;
  display: flex;
  justify-content: center;
  padding: #{$btn-padding-y} #{$btn-padding-x};
  width: 128px;

  svg {
    color: inherit !important;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-light-grey-hover);
    color: var(--bs-red) !important;
  }

  &:focus-visible {
    background-color: var(--bs-light-grey-50);
    color: var(--bs-red) !important;
  }

  &.dark {
    background: var(--bs-medium-grey);

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background: #30384b;
    }

    &:focus-visible {
      background: rgba(var(--bs-medium-grey-rgb), 0.2);
      color: var(--bs-red) !important;
    }
  }
}
