@import 'src/theme/theme';

.tabs {
  &-tab {
    border-bottom: 2px solid transparent;
    color: var(--bs-silver);
    cursor: pointer;
    font-family: $font-family-sans-serif;
    min-height: 56px;
    padding: 0 16px;
    transition: all 0.15s ease-in-out;

    @include media-breakpoint-down(md) {
      min-height: 66px;
    }

    &:hover {
      border-color: rgba($primary, 0.1);
      color: var(--bs-primary);
    }
  }

  &-active,
  &-active:hover {
    border-color: var(--bs-primary);
    color: var(--bs-primary);
  }
}
