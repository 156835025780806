.feed-card-action-icon {
  align-items: center;
  background-color: var(--bs-white-bg);
  border-radius: 100%;
  bottom: -6%;
  display: flex;
  height: 44%;
  justify-content: center;
  position: absolute;
  right: -6%;
  width: 44%;

  svg {
    height: auto;
    width: 58%;
  }
}
