.tiers-header {
  margin-top: 50px;
  text-align: center;

  &-title {
    font-size: 18px;
    font-weight: 700;
  }

  &-text {
    color: var(--bs-silver);
  }
}
