@import '/src/theme/theme';

.nft-content {
  box-shadow: 0 -16px 24px rgba(0, 0, 0, 0.04);
  margin-top: -40px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 50;

  @include media-breakpoint-up(md) {
    box-shadow: -16px 16px 24px rgba(0, 0, 0, 0.04);
  }

  @include media-breakpoint-up(lg) {
    height: 100vh;
    margin-top: 0;
    overflow: auto;
    overscroll-behavior: contain;
    position: fixed;
    right: 0;
    top: 0;
    width: $sidebar-width;
  }

  &-loading {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
  }

  &-inner {
    background: var(--bs-body-bg);
    border-radius: 20px 20px 0 0;
    min-height: 100%;
    padding-bottom: 2px;
    position: relative;

    @include media-breakpoint-up(lg) {
      border-radius: 0;
    }

    > * {
      margin-bottom: 40px;
    }

    &::before {
      background: var(--bs-light-grey);
      border-radius: 3px;
      content: '';
      height: 4px;
      left: 50%;
      position: absolute;
      top: 16px;
      transform: translateX(-50%);
      width: 60px;
      @include media-breakpoint-up(lg) {
        content: none;
      }
    }
  }
}
