@import 'src/theme/theme';

.like-button {
  border-width: 0;
  color: var(--bs-secondary);
  display: flex;
  font-weight: 500;
  justify-content: center;

  // .dark-theme & {
  //   background-color: var(--bs-medium-grey);
  //   color: var(--bs-white) !important;

  //   &:hover {
  //     background: linear-gradient(0deg, rgba(var(--bs-pink-rgb), 0.1), rgba(var(--bs-pink-rgb), 0.1)), var(--bs-medium-grey);
  //   }

  //   &:active {
  //     background: linear-gradient(0deg, rgba(var(--bs-pink-rgb), 0.2), rgba(var(--bs-pink-rgb), 0.2)), var(--bs-medium-grey);
  //   }
  // }

  svg {
    color: inherit !important;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: rgba(var(--bs-pink-rgb), 0.15);
    color: var(--bs-pink) !important;
  }

  &:focus-visible {
    background-color: rgba(var(--bs-pink-rgb), 0.2);
    color: var(--bs-pink) !important;
  }
}
