@import 'src/theme/theme';

$offset: 7px;

.asset-card-badges {
  left: -$offset;
  position: absolute;
  right: -$offset;
  top: -$offset;
  z-index: 2;

  .verified-badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(20%);
    width: 11%;
  }
}
