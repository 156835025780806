@import 'src/theme/theme';

.account-header {
  .username {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
    margin-top: 15px;
  }
}
