.dropdown-menu {
  background: var(--bs-card-bg);
  border: 0;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: var(--bs-dark-grey);
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0;
}
  
.dropdown-item {
  font-weight: 500;
  padding: 8px 22px;

  &:hover,
  &:active {
    background-color: rgba(var(--bs-secondary-btn-rgb), 0.07);
    color: inherit;
  }

  &.active {
    background-color: transparent;
    color: var(--bs-primary);

    &:hover,
    &:active {
      background-color: var(--bs-light-grey-50);
      color: var(--bs-primary);
    }
  }
}

.dropdown-btn {
  align-items: center;
  background-color: var(--bs-white);
  border-color: var(--bs-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  color: var(--bs-icon-text);
  display: flex;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 18px;

  .dark-theme & {
    background-color: rgba(var(--bs-secondary-btn-rgb), 0.07);
    border-color: transparent;
    color: var(--bs-white);
  }


  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-light-grey-hover);
    border-color: var(--bs-light-grey-hover);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    color: var(--bs-icon-text);

    .dark-theme & {
      background-color: rgba(var(--bs-secondary-btn-rgb), 0.14);
      border-color: transparent;
      color: var(--bs-white);
    }
  }

  &:focus-visible {
    background: var(--bs-white-bg);
    border-color: var(--bs-primary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    color: var(--bs-icon-text);
    outline: none;
  }
}
