.subdomain-media-link {
  align-items: center;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  height: 250px;
  justify-content: center;
  margin-bottom: 25px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  text-decoration: none;
    
  &-text {
    align-items: center;
    color: var(--bs-white);
    display: flex;
    font-size: 22px;
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 86.81%);
    bottom: 0;
    content: '';
    height: 166px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
}
