@import 'src/theme/theme';

.rolling-collection {
  &-infos {
    height: 100%;
    left: 0;
    padding: 24px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 21;
  }

  &-name {
    color: var(--bs-white);
    font-size: 24px;
    font-weight: 700;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  &-subtitle {
    color: var(--bs-white);
    font-size: 12px;
    letter-spacing: 0.1em;
    opacity: 0.75;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }
}
