.nft-page-views {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;

  svg {
    color: var(--bs-icon-text);
  }

  &--label {
    margin-left: 4px;
  }

  &--count {
    margin-left: 8px;
  }
}
