@import './src/theme/theme';

.loading-cards {
  overflow: hidden;

  .row {
    margin-left: -6.5px;
    margin-right: -6.5px;

    > * {
      padding-left: 6.5px;
      padding-right: 6.5px;
    }
  }

  &:not(.small-on-mobile) {
    width: 100%;

    .row {
      flex-wrap: nowrap;
    }
  }

  &.small-on-mobile {
    div:nth-child(4) {
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    div:nth-child(3) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    div:nth-child(2) {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
