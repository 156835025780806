.user-details {
  font-size: 14px;
  line-height: 19px;
  
  &.disabled {
    pointer-events: none;
  }

  &-right {
    min-width: 0;
  }

  a {
    pointer-events: auto;
    text-decoration: none;
  }

  .user-image {
    height: 40px;
    margin-right: 12px;
    width: 40px;
  }
}
