@import './src/theme/theme';

.boolean-buttons-field {
  font-size: 13px;

  input {
    opacity: 0;
    position: absolute;

    &:checked + label {
      border-color: var(--bs-primary);

      span {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
      }

      svg {
        opacity: 1;
      }
    }
  }

  label {
    border: 1px solid var(--bs-light-grey);
    border-radius: 12px;
    color: var(--bs-dark-blue);
    cursor: pointer;
    padding: 19px;
    width: 100%;
  }

  span {
    background: rgba($primary, 0.08);
    border: 1px solid rgba($primary, 0.12);
    border-radius: 24px;
    display: inline-block;
    font-size: 11px;
    height: 24px;
    margin-right: 14px;
    position: relative;
    width: 24px;

    svg {
      color: var(--bs-white);
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
