@import 'src/theme/theme';

.featured-link {
  $self: &;
  background: var(--bs-light-grey);
  border-radius: $card-border-radius;
  box-shadow: 0 0 transparent;
  display: block;
  margin-bottom: 12px;
  overflow: hidden;
  padding-top: 90.8%;
  position: relative;
  will-change: transform;

  &:hover {
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);

    #{$self}-background {
      transform: scale(1.05);
    }
  }

  .dark-theme & {
    background: var(--bs-medium-grey);
  }

  &-background {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    padding: 12px;
    position: absolute;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      padding: 24px;
    }
  }

  &-title {
    color: var(--bs-white);
    font-size: 15px;
    font-weight: 700;
    line-height: 1.2;
    max-width: 120px;

    @include media-breakpoint-up(lg) {
      font-size: 21px;
      line-height: 1.4;
      max-width: 180px;
    }
  }

  &-subtitle {
    color: var(--bs-white);
    font-size: 12px;
    line-height: 1.2;
    max-width: 80%;
    opacity: 0.8;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
      line-height: 1.4;
    }
  }

  &-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    bottom: 0;
    height: 50%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }
}
