@import 'theme/theme';

.refresh-button {
  &-wrapper {
    margin-top: -120px;
    position: fixed;
    z-index: 9;
  }

  &-icon-container {
    align-items: center;
    background-color: var(--bs-primary-lighten-10);
    border-radius: 50%;
    display: flex;
    height: 28px;
    justify-content: center;
    width: 28px;
  }

  &-message {
    margin-left: 9px;
    margin-right: 20px;
  }
}
