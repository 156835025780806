@import './src/theme/theme';

.collection-card-asset {
  background: linear-gradient(180deg, var(--bs-white-bg) 0%, var(--bs-light-grey) 50%, var(--bs-white-bg) 100%);
  background-size: 100% 200%;
  margin-bottom: var(--bs-gutter-x);
  overflow: hidden;
  padding-top: calc(50% - var(--bs-gutter-x));
  position: relative;
  width: calc(50% - var(--bs-gutter-x));
  will-change: transform;

  &:nth-child(2n + 1) {
    margin-right: calc(2 * var(--bs-gutter-x));
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }

  &:nth-child(1) {
    border-top-left-radius: 5px;
  }
  
  &:nth-child(2) {
    border-top-right-radius: 5px;
  }

  &.no-asset {
    background: linear-gradient(180deg, var(--bs-light-grey) 0%, rgba(var(--bs-light-grey-rgb), 0) 100%);
    border: 1px solid var(--bs-light-grey);
    box-sizing: border-box;
    position: relative;
  }

  &-empty {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .collection-card-empty-circle {
    font-size: 14px;
  }

  .small-on-mobile & {
    .collection-card-empty-circle {
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
  }
}

.collection-card {
  $self: &;

  &:hover {
    #{$self}-asset img {
      transform: scale(1.05);
    }
  }
}
