@import './src/theme/theme';
@import 'nft-page/mixins';

.nft-page-auction {
  @include nft-page-content-padding-x;

  &-border {
    @include gradient-box($bg-color: var(--bs-body-bg), $border-width: 1px);
    box-shadow: 0 0 16px rgba(238, 98, 32, 0.15);

    &:empty {
      background: none;
      padding: 0;
    }
  }

  &-inner {
    padding: 30px 16px 16px;
  }
}
