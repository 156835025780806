@import './src/theme/theme';

@keyframes moving {
  0% {
    background-position: center 0%;
  }

  100% {
    background-position: center 100%;
  }
}

.ghost-collection-card {
  --bs-gutter-x: 1px;
  background-color: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;

  &:not(.small-on-mobile) {
    width: 294.7px;
  }

  &-image {
    animation: moving 1.5s linear infinite alternate;
    background: linear-gradient(180deg, var(--bs-white-bg) 0%, var(--bs-light-grey) 50%, var(--bs-white-bg) 100%);
    background-size: 100% 200%;
    margin-bottom: calc(2 * var(--bs-gutter-x));
    overflow: hidden;
    padding-top: calc(50% - var(--bs-gutter-x));
    position: relative;
    position: relative;
    width: calc(50% - var(--bs-gutter-x));
    will-change: transform;

    &:nth-child(2n + 1) {
      margin-right: calc(2 * var(--bs-gutter-x));
    }

    &:nth-child(1) {
      border-top-left-radius: 5px;
    }
    
    &:nth-child(2) {
      border-top-right-radius: 5px;
    }
  }

  &-images {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &-content {
    padding-top: 90px;
  }

  .small-on-mobile &-content {
    @include media-breakpoint-down(sm) {
      padding-top: 57px;
    }
  }
}
