@import 'src/theme/theme';

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

.transparent-content {
  animation: zoom-in 0.2s;
  background-color: transparent;
  border: 0;
}

.modal-body {
  max-width: 100%;
  padding: 0.3rem;
}

.modal-content {
  background-color: var(--bs-body-bg);
  border: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  padding: $modal-content-padding;

  @include media-breakpoint-down(md) {
    padding: $modal-content-padding-mobile;
  }
}

.modal-fullscreen {
  height: auto;
  min-height: 100%;

  .modal-body {
    overflow: visible;
  }
}

.modal-backdrop.show {
  background-color: #fff;
  opacity: 0.7;

  .dark-theme & {
    background-color: #000;
    opacity: 0.8;
  }
}
