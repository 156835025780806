.price-field-currency {
  svg {
    height: 32px;
    margin-right: 5px;
    width: 32px;
  }

  &-currency {
    color: var(--bs-dark-blue);
    font-size: 14px;
    line-height: 1.2;
  }
}
