.artist-account {
  a {
    text-decoration: none;
  }

  .user-image {
    width: 48px;
  }

  &-title {
    color: var(--bs-icon-text);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 12px;
  }

}
