@import 'src/theme/theme';

.award {
  align-items: center;
  background-color: var(--bs-card-bg);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  justify-content: center;
  min-height: 193px;
  padding: 40px 32px;
  position: relative;
  
  &-image {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    max-height: 60px;
    max-width: 133px;
  }

  &-title {
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
  }

  &-icon {
    background: url('/startup/assets/metaspace-award-icon.png');
    background-size: cover;
    height: 70px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%) translateY(-50%);
    width: 52px;
  }
}
