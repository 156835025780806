@import 'src/theme/theme';

.featured-page-footer {
  margin-bottom: 40px;
  text-align: center;

  &-inner {
    background: linear-gradient(360deg, rgba(198, 208, 240, 0) 0%, rgba(var(--bs-blue-25-rgb), 0.15) 100%);
    border-radius: 6px;
    padding: 48px 24px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  p {
    color: var(--bs-icon-text);
    font-size: 14px;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  .btn {
    min-width: 160px;

    @include media-breakpoint-up(md) {
      min-width: 240px;
    }
  }
}
