@import './src/theme/theme';

.asset-card-details {
  padding: 18px 12px;
  pointer-events: none;
  position: relative;

  .user-image {
    border: 0;
    filter: none;
  }

  .user-info {
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }

  .small-on-mobile & {
    @include media-breakpoint-down(sm) {
      padding: 12px 8px;
    }

    .user-info {
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }

    .user-info-title {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
