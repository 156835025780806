.search-results {
  background: var(--bs-light-grey-bg);
  border: 2px solid var(--bs-light-grey);
  border-radius: 8px;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);
  margin-top: 10px;
  max-height: 70vh;
  min-width: 100%;
  overflow: auto;
  padding: 12px 0;
  position: absolute;
}
