// accordion
$accordion-border-color: var(--bs-white);
$accordion-color: var(--bs-dark-blue);
$accordion-bg: var(--bs-white-bg);
$accordion-button-bg: var(--bs-card-bg);
$accordion-button-active-bg: var(--bs-card-bg);
$accordion-button-active-color: var(--bs-dark-blue);
$accordion-button-focus-border-color: var(--bs-card-border-color);
$accordion-button-focus-box-shadow: var(--bs-white);

// inputs
$component-active-bg: $primary;
$input-color: var(--bs-dark-blue);
$input-bg: var(--bs-body-bg);
$input-focus-bg: var(--bs-body-bg);
$input-focus-color: var(--bs-dark-blue);
$input-placeholder-color: var(--bs-silver);
$input-border-color: rgba(var(--bs-secondary-rgb), 0.3);;
$input-focus-border-color: rgba(var(--bs-secondary-btn-rgb), 0.25);
$input-disabled-bg: transparent;
$form-check-input-bg: $input-bg;
$form-check-input-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-secondary-btn-rgb), 0.25);
$form-check-input-border: 1px solid var(--bs-white);
$form-check-input-focus-border: var(--bs-primary);
$form-check-input-checked-bg-color: var(--bs-primary);
$form-check-input-checked-border-color: var(--bs-primary);

// labels
$form-label-color: var(--bs-dark-blue);

// dropdown
$dropdown-bg: var(--bs-white-bg);
$dropdown-border-color: var(--bs-blue-25);
$dropdown-link-color: var(--bs-dark-blue);
$dropdown-link-hover-color: var(--bs-dark-blue);
$dropdown-link-hover-bg: var(--bs-blue-10);

// tooltips
$tooltip-bg: var(--bs-tooltip-bg);
$tooltip-arrow-color: $tooltip-bg;
$tooltip-color: var(--bs-dark-blue);
