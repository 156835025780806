@import 'src/theme/theme';

.share-button {
  border: 0;
  color: var(--bs-secondary);
  font-weight: 500;

  @include media-breakpoint-down(sm) {
    padding-right: 24px;
  }

  span {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-container {
    position: relative;
  }

  &-list {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 6px 0;

    button {
      border: 0;
      color: var(--bs-body-color);
      text-align: left;
      white-space: nowrap;
      width: 100%;

      &:hover {
        color: var(--bs-primary);
        cursor: pointer;
      }

      svg {
        margin-right: 6px;
        width: 1em !important;
      }
    }
  }
}
