@import 'src/theme/theme';

.featured-section {
  margin-bottom: 60px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }
  
  .row {
    margin-left: -6px;
    margin-right: -6px;
         
    > div {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
