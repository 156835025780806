@import './src/theme/theme';

.data-card {
  margin-bottom: 60px;

  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }

  .container & .container {
    padding: 0;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }

  .swiper-wrapper {
    padding-bottom: 25px;
  }

  .row {
    margin-left: -3px;
    margin-right: -3px;

    @include media-breakpoint-up(sm) {
      margin-left: -12px;
      margin-right: -12px;
    }

    > div {
      padding-left: 3px;
      padding-right: 3px;

      @include media-breakpoint-up(sm) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }  
  }

  .card-item {
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 32px;
    }
  }
}
