.nft-page-bundle {
  background: var(--bs-card-bg);
  border: 1px solid var(--bs-light-grey);
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--bs-dark-blue);
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 24px;
  padding: 16px 12px;

  &-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  &-subtitle {
    font-size: 12px;
  }
}

.nft-page-bundle-image {
  height: 14px;
  width: 12px;
}
