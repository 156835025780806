@import 'src/theme/theme';

.on-sale-filter {
  align-items: center;
  background-color: var(--bs-primary);
  border-radius: $btn-border-radius;
  display: flex;

  * {
    cursor: pointer;
  }

  &-inner {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;

    @include media-breakpoint-up(md) {
      padding: 13px 16px;
    }
  }

  .form-switch {
    margin-bottom: 0;
  }

  .form-check {
    align-items: center;
    display: flex;
    padding: 0;

    &-label {
      color: var(--bs-white);
      display: initial;
      padding-right: 10px;
      white-space: nowrap;
    }

    &-input {
      float: none;
      margin-left: 0;
      margin-top: 0;

      @include media-breakpoint-down(md) {
        height: 1.2rem;
        width: 2rem;
      }
    }
  }
}
