.tag-button {
  background-color: var(--bs-white-bg);
  border-color: var(--bs-white-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 18px;

  .dark-theme & {
    background-color: var(--bs-medium-grey);
    border-color: var(--bs-medium-grey);
  }

  &:disabled {
    cursor: pointer;
    pointer-events: auto;
  }

 
  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-light-grey-hover);
    border-color: var(--bs-light-grey-hover);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);

    .dark-theme & {
      background-color: var(--bs-blue-50);
      border-color: var(--bs-blue-50);
    }
  }

  &:focus-visible {
    background: var(--bs-white-bg);
    border-color: var(--bs-primary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    outline: none;
  }

  &.active {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background-color: var(--bs-primary-lighten-10);
      border-color: var(--bs-primary-lighten-10);
    }

    &:focus-visible {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary-darken-20);
    }

    .tag-button-text {
      color: var(--bs-white);
    }
  }
}

.tag-button-image {
  font-size: 14px;
  line-height: 1.35;
  margin-right: 8px;
  width: 15px;
}

.tag-button-text {
  color: var(--bs-silver);
  display: inline-block;
}
