@import 'nft-page/mixins';

.nft-page-other-listings {
  &-title {
    @include nft-page-content-padding-x;
    border-bottom: 1px solid rgba(var(--bs-secondary-rgb), 0.4);
    color: var(--bs-icon-text);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  &-content {
    @include nft-page-content-padding-x();
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
    padding-top: 32px;
  }
}
