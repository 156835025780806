@import './src/theme/theme';

@keyframes moving {
  0% {
    background-position: center 0%;
  }

  100% {
    background-position: center 100%;
  }
}

.loading-cards {
  &-card {
    background-color: var(--bs-card-bg);
    border: $card-border-width solid var(--bs-card-border-color);
    border-radius: $card-border-radius;

    .loading-cards:not(.small-on-mobile) & {
      width: 294.7px;
    }
  }

  &-image {
    animation: moving 1.5s linear infinite alternate;
    background: linear-gradient(180deg, var(--bs-body-bg) 0%, rgba(var(--bs-secondary-rgb), 0.1) 50%, var(--bs-body-bg) 100%);
    background-size: 100% 200%;
    border-radius: 5px 5px 0 0;
    padding-top: 100%;
    position: relative;
  }

  &-content {
    padding-top: 90px;
  }

  .small-on-mobile &-content {
    @include media-breakpoint-down(sm) {
      padding-top: 57px;
    }
  }
}
