@import '/src/theme/theme';

.nft-page-key-pair {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &-title {
    align-items: center;
    color: var(--bs-silver);
    display: flex;
    font-size: 12px;
    font-weight: 600;


    @include media-breakpoint-between(md, lg) {
      justify-content: start;
    }
  }

  &-icon {
    margin-right: 7px;
    text-align: center;
    width: 14px;
  }

  &-value {
    color: var(--bs-silver);
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: flex-end;
    line-height: 17px;
    max-width: 60%;
    min-width: 0;

    .accent {
      color: var(--bs-body-color);
    }
  }

  svg path {
    fill: var(--bs-silver);
  }
}
