@import './src/theme/theme';

.tags-field {
  .tagify {
    border: 0;
    border-bottom: 2px solid var(--bs-light-grey);
    font-size: 12px;

    &.tagify--focus {
      border-color: var(--bs-medium-grey);
    }

    &__input {
      padding: 5px;
    }

    &__tag {
      &:first-child {
        margin-left: 0;
      }

      > div {
        border-radius: 8px;
        color: var(--bs-primary);
        padding: 9px 12px;

        * {
          word-break: break-word;
        }

        &::before {
          border: 1px solid var(--bs-light-grey);
          box-shadow: none;
        }
      }

      &:hover > div::before {
        border: 1px solid var(--bs-primary);
        box-shadow: none;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      &.tagify--notAllowed > div::before {
        border: 1px solid var(--bs-pink);
        box-shadow: none !important;
      }

      &__removeBtn {
        color: var(--bs-silver);

        &:hover {
          background: var(--bs-pink);
          color: var(--bs-white);

          /* stylelint-disable-next-line max-nesting-depth */
          + div::before {
            border: 1px solid var(--bs-pink);
            box-shadow: none !important;
          }
        }
      }
    }
  }
}
