@import './src/theme/theme';

.asset-card {
  &-creator {
    margin-top: 6px;
  }

  &.small-on-mobile {
    .asset-card-creator {
      @include media-breakpoint-down(sm) {
        margin-top: 3px;
      }
    }
  }
}
