@import 'src/theme/theme';

.rolling-collection {
  $self: &;

  background: var(--bs-light-grey);
  border-radius: $card-border-radius;
  box-shadow: 0 0 transparent;
  display: block;
  margin-bottom: 16px;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
  will-change: transform;

  &:hover {
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);

    #{$self}-images {
      transform: scale(1.03);
    }
  }

  .dark-theme & {
    background: var(--bs-medium-grey);
  }

  &-images {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }

  &-images {
    .rolling-collection-image {
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      width: 100%;

      &.active {
        z-index: 20;
      }
    }
  }
}
