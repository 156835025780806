.footer {
  background-color: var(--bs-footer-bg);
  color: var(--bs-silver);
  font-size: 12px;
  
  &-hidden {
    display: none;
  }
}

