@import 'src/theme/theme';

.dropzone {
  &.preview {
    padding: 1rem;
  }

  &-close {
    margin-top: #{10px - $modal-content-padding};

    @include media-breakpoint-down(md) {
      margin-top: #{10px - $modal-content-padding-mobile};
    }
  }
}
