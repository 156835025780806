@import './src/theme/theme';

.profile-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15.6px;
  text-align: right;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
  
  @include media-breakpoint-up(xl) {
    justify-content: end;
  }

  > * {
    margin-bottom: 16px;
  
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }  
  }
}
