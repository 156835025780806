@import 'src/theme/theme';

.follow-button {
  background: linear-gradient(260.76deg, #3262df 0%, var(--bs-primary) 100%);
  border: 0 !important;
  box-shadow: 0 4px 8px rgba(0, 31, 156, 0.16) !important;
  color: var(--bs-white) !important;
  display: flex;
  justify-content: center;
  padding: $btn-padding-y $btn-padding-x;
  width: 128px;

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background: linear-gradient(260.76deg, #6086e6 0%, #3262df 100%);
    color: var(--bs-white);
  }

  &:focus-visible {
    background: linear-gradient(260.76deg, var(--bs-primary) 0%, var(--bs-primary) 100%);
    color: var(--bs-white);
    outline: none;
  }
}
