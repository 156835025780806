.nft-page-likes {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 16px;

  svg {
    color: var(--bs-silver);
  }

  &--label {
    color: rgba(var(--bs-dark-blue-rgb), 0.4);
    margin-left: 6px;
  }

  &--count {
    margin-left: 12px;
  }
}
