@import './src/theme/theme';

.collection-card {
  &-title {
    color: var(--bs-body-color);
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-col {
      min-width: 0;
    }
  }

  &.small-on-mobile {
    .collection-card-title {
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }
}
