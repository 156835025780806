@import '/src/theme/theme';

.infinite-scroll__load-trigger {
  position: relative;
  top: -404px;

  @include media-breakpoint-down(md) {
    top: -324px;
  }
}
