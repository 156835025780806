@import './src/theme/theme';

.collection-card {
  &-creator {
    margin-top: 6px;
  }

  &.small-on-mobile {
    .collection-card-creator {
      @include media-breakpoint-down(sm) {
        margin-top: 3px;
      }
    }
  }
}
