@import '../../theme/theme';

.suggestion-card {
  background: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 16px 12px;
  position: relative;
  transition: all 0.3s ease-in-out;

  @include media-breakpoint-up(md) {
    margin-bottom: 10px;
    padding: 21px 16px;
  }

  &:hover {
    background-color: var(--bs-card-bg-hover);
    border-color: var(--bs-card-hover-border-color);
    box-shadow: $card-box-shadow-hover;
  }

  &-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  
    &:focus-visible {
      border-radius: $card-border-radius;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      outline: 2px solid var(--bs-primary);
    }
  }

  &-text-container {
    margin: 0 20px 0 12px;
    min-width: 0;
    width: 40%;

    .username {
      font-weight: 600;
    }
  }

  &-description {
    color: var(--bs-icon-text);
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user-image {
    flex: 0 0 40px;
    width: 40px;
  }

  .btn {
    position: relative;
    width: 100%;
    z-index: 10;
    
    @include media-breakpoint-down(md) {
      font-size: 12px;
      padding: 10px 20px;
      width: auto;
    }
  }
}
