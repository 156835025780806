@import './src/theme/theme';

.percent-field {
  .react-select {
    &__control {
      background-color: var(--bs-white-bg);
      border-color: $input-border-color;
      border-radius: 0;
      border-width: 0 0 2px;
     
      min-height: 41px;

      &:hover {
        border-color: $input-border-color;
      }

      &--is-focused {
        border-color: $input-focus-border-color;
        box-shadow: none;
        outline: 0;

        &:hover {
          border-color: $input-focus-border-color;
        }
      }
    }

    &__single-value {
      color: var(--bs-dark-blue);
    }

    &__indicator-separator {
      background-color: var(--bs-medium-grey);
    }

    &__menu {
      background-color: var(--bs-white-bg);
      box-shadow: 0 0 0 1px rgba(var(--bs-dark-blue-rgb), 0.1), 0 4px 11px rgba(var(--bs-dark-blue-rgb), 0.1);
    }

    &__option {
      &--is-focused {
        background-color: var(--bs-blue-10);
      }

      &--is-selected {
        background-color: var(--bs-primary);
      }

      &:active {
        background-color: var(--bs-blue-25);
      }
    }
  }

  
}
