@use 'sass:math';
@import 'src/theme/theme';

.featured-page-text-image {
  margin: 0 0 30px;
  
  @include media-breakpoint-up(md) {
    margin: 40px 0;  
  }

  &-counter::before {
    color: var(--bs-primary);
    content: '0' counter(section) '.';
    counter-increment: section;
    display: block;
    font-weight: 500;
    margin-bottom: 0.9em;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0.75em;

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }
  }

  p {
    color: var(--bs-icon-text);
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  strong {
    font-weight: 600;
  }

  a,
  .btn-link {
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .content-col {
    order: 2;
    text-align: center;

    @include media-breakpoint-up(md) {
      order: 0;
      padding-left: 5vw;
      text-align: right;
    }
  }

  .image-col {
    img {
      max-width: 95%;
      width: 250px;

      @include media-breakpoint-up(md) {
        max-width: 95%;
        width: auto;
      }
    }
  }

  &.reversed {
    .content-col {
      order: 2;
      text-align: center;

      @include media-breakpoint-up(md) {
        
        padding-left: #{math.div($grid-gutter-width, 2)};
        padding-right: 5vw;
        text-align: left;
      }
    }
  }
}
