.unlike-button {
  background-color: var(--bs-pink);
  border: 0;
  color: var(--bs-white) !important;
  display: flex;
  font-weight: 500;
  justify-content: center;

  svg {
    color: inherit !important;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: rgba(var(--bs-secondary-btn-rgb), 0.14);
    border-color: rgba(0, 0, 0, 0.04);
    color: var(--bs-pink) !important;
  }


  &:focus-visible {
    background-color: rgba(var(--bs-secondary-btn-rgb), 0.21);
    color: var(--bs-pink) !important;
  }
}
