@import 'src/theme/theme';

.clickable {
  cursor: pointer;
}

.label-field {
  color: var(--bs-silver);
  font-size: 14px;
  margin-bottom: 2px;
}

.icon-field {
  color: var(--bs-silver);
  font-size: 12px;
}

.nerds-form-field {
  margin-bottom: 25px;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-bottom: 33px;
  }

  .text-danger {
    bottom: -21px;
    position: absolute;
  }
}
