@import '/src/theme/theme';

%counter {
  align-items: center;
  border-radius: 19px;
  font-size: 12px;
  height: 28px;
  min-width: 28px;
  padding: 0 9px;
  transition: all 0.15s ease-in-out;

  @include media-breakpoint-up(md) {
    margin-right: 8px;
  }
}

.tabs {
  &-tab {
    font-weight: 500;
  }

  &-counter {
    &-inactive {
      @extend %counter;
      background: rgba(136, 153, 179, 0.1);

      color: var(--bs-silver);
    }

    &-active {
      @extend %counter;
      background-color: var(--bs-primary);

      color: var(--bs-light-grey-bg);
    }
  }

  &-tab:hover {
    .tabs-counter-inactive {
      color: var(--bs-primary);
    }
  }
}
