@import 'src/theme/theme';

.onboarding-sell {
  &-steps {
    display: flex;
    margin-bottom: 24px;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }

    &::before {
      background-color: var(--bs-blue-10);
      content: '';
      height: 2px;
      left: 20%;
      position: absolute;
      right: 20%;
      top: 20px;

      @include media-breakpoint-up(md) {
        top: 24px;
      }
    }
  }

  &-step {
    align-items: center;
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    position: relative;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    svg {
      height: auto;
      margin-bottom: 12px;
      width: 40px;

      @include media-breakpoint-up(md) {
        width: 49px;
      }

      circle {
        fill: var(--bs-blue-10);
        opacity: 1;
      }
    }
  }
}
