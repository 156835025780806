.verified-badge {
  display: inline-block;
  height: 1em;
  min-width: 1.35em;
  position: relative;
  vertical-align: middle;
  width: 1.35em;

  svg {
    height: auto;
    left: 0;
    position: absolute;
    top: 39%;
    transform: translateY(-50%);
    width: 100%;    
  }

  .verified--inner {
    fill: var(--bs-primary-hover);
    transform: scale(0.9);
    transform-origin: center;
  }

  .dark-theme & {
    .verified--outer {
      fill: var(--bs-primary-hover);
    }
  }
}
