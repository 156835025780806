@use 'sass:math';
@import './src/theme/theme';

.explore-nfts-filter {
  height: 53px;
  margin-bottom: 24px;

  @include media-breakpoint-down(sm) {
    margin: 0 -#{math.div($grid-gutter-width, 2)} 32px !important;
  }

  .swiper {
    margin-left: 0;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding: 0 #{math.div($grid-gutter-width, 2)};
    }
  }

  .swiper-wrapper {
    padding: 0 0 6px;
  }

  .swiper-slide {
    width: auto !important;
  }
}
