.accordion-button {
  border-bottom: 2px solid var(--bs-light-grey);
  color: var(--bs-dark-blue);
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 0.85rem 0;

  &::after {
    content: none;
  }

  svg {
    background-size: 100%;
    font-size: 11px;
    height: 14px;
    transform: rotate(0deg);
    transition: all 0.15s ease-in-out;
    width: 14px;
  }

  &:not(.collapsed) svg {
    transform: rotate(-180deg);
  }

  .accordion-item:last-of-type &.collapsed {
    border-radius: 0;
  }
}
