.final-fee-field {
  background: var(--bs-card-bg);
  border: 2px solid var(--bs-card-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 32px;

  .accordion-body {
    padding-bottom: 8px;
    padding-top: 0;
  }
}

.final-fee-icon {
  margin-right: 12px;
}

.final-fee-item {
  margin-bottom: 16px;
}
