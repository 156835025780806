@import './src/theme/theme';

.collection-card-empty {
  &-icon {
    color: var(--bs-silver);
  }

  &-circle {
    align-items: center;
    background-color: var(--bs-white-bg);
    border: 1px solid var(--bs-card-border-color);
    border-radius: 50%;
    display: flex;
    font-size: 21px;
    height: 2.28em;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 2.28em;
  }  

  .small-on-mobile &-circle {
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
}


