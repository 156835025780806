@import 'src/theme/theme';

.collection-card {
  background-color: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:hover {
    background-color: var(--bs-card-bg-hover);
    border-color: var(--bs-card-hover-border-color);
    box-shadow: $card-box-shadow-hover;

    &.is-sft {
      &::before,
      &::after {
        border-color: rgba(var(--bs-card-hover-border-color-rgb), 0.75);

      }

      &::after {
        border-color: rgba(var(--bs-card-hover-border-color-rgb), 0.5);
      }
    }
  }

  .verified-badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(40%) translateY(-40%);
    width: 11%;
  }

  &.is-sft {
    &::before,
    &::after {
      background-color: var(--bs-card-bg);
      border: $card-border-width solid var(--bs-card-border-color);
      border-radius: $card-border-radius;
      bottom: -6px;
      content: '';
      height: 30px;
      left: 50%;
      pointer-events: none;
      position: absolute;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;
      width: 95%;
      z-index: -1;
    }

    &::after {
      bottom: -10px;
      box-shadow: $card-box-shadow;
      width: 87%;
      z-index: -2;
    }
  }
 
}
