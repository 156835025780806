@use 'sass:math';
@import './src/theme/theme';

.account-tabs {
  font-size: 16px;
  margin-top: 40px;

  &-header {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      margin-left: -#{math.div($grid-gutter-width, 2)} !important;
      margin-right: -#{math.div($grid-gutter-width, 2)} !important;
    }
  }

  .swiper {
    max-width: 100%;
  }

  .swiper-slide {
    padding: 0 5px;
    width: auto !important;
  }
}
