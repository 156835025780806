@import './src/theme/theme';

.profile-followers {
  align-items: center;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
  padding: $btn-padding-y $btn-padding-x;

  &:not(.btn) {
    background: rgba(136, 153, 179, 0.1);
  }

  &-label {
    color: rgba(var(--bs-dark-blue-rgb), 0.4);
    margin-left: 6px;
  }

  &-count {
    font-weight: 500;
  }

  .btn {
    box-shadow: none !important;
  }
}
