@import 'src/theme/theme';

.auctions-tab {
  align-items: center;
  background: rgba(var(--bs-secondary-rgb), 0.07);
  border: 2px solid $input-border-color;
  border-radius: 8px;
  color: var(--bs-dark-blue);
  cursor: pointer;
  display: flex;
  padding: 16px;
  transition: all 0.15s ease-in-out;
  width: 140px;

  &-title {
    color: var(--bs-dark-blue);
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
    transition: all 0.15s ease-in-out;
  }

  &-icon {
    align-items: center;
    background: rgba(var(--bs-secondary-rgb), 0.1);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    transition: all 0.15s ease-in-out;
    width: 100%;

    svg path {
      fill: var(--bs-silver);
      transition: all 0.15s ease-in-out;
    }
  }

  &-check {
    align-items: center;
    background: var(--bs-primary);
    border-radius: 50px;
    color: var(--bs-white);
    display: none;
    height: 24px;
    justify-content: center;
    left: -8px;
    position: absolute;
    top: -8px;
    width: 24px;
  }

  &:hover {
    border: 2px solid $input-focus-border-color;
    box-shadow: 0 8px 12px rgba(26, 69, 194, 0.1);

    .auctions-tab-title {
      color: var(--bs-primary);
    }

    .auctions-tab-icon {
      background: rgba(var(--bs-secondary-btn-rgb), 0.1);

      svg path {
        fill: var(--bs-primary);
        opacity: 1;
      }
    }
  }

  &.active {
    border-color: var(--bs-primary);
    box-shadow: 0 8px 12px rgba(26, 69, 194, 0.1);

    .auctions-tab-title {
      color: var(--bs-primary);
    }

    .auctions-tab-icon {
      background: rgba(var(--bs-secondary-btn-rgb), 0.1);

      svg path {
        fill: var(--bs-primary);
        opacity: 1;
      }
    }

    .auctions-tab-check {
      display: flex;
    }
  }

  &:focus {
    border-color: var(--bs-primary);
    outline: none;
  }
}
