@import './src/theme/theme';

.search-input {
  background-color: var(--bs-light-grey-50);
  border: 2px solid var(--bs-light-grey-50);
  border-radius: 100px;
  box-shadow: 0 10px 24px transparent;
  font-size: 13px;
  height: 48px;
  padding: 17px 20px;
  padding-right: 48px;

  &:focus {
    background-color: var(--bs-white-bg);
    border-color: var(--bs-light-grey);
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);

    + .search-input-icon {
      color: var(--bs-dark-blue);
    }
  }

  &:-webkit-autofill {
    background-clip: content-box;
    box-shadow: 0 0 0 30px var(--bs-light-grey-50) inset !important;
    -webkit-text-fill-color: #{$input-color} !important;
  }

  &:autofill {
    background-clip: content-box;
    box-shadow: 0 0 0 30px var(--bs-light-grey-50) inset !important;
    -webkit-text-fill-color: #{$input-color} !important;
  }

  &-wrapper {
    margin: 0 auto;
    max-width: 416px;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: initial;
    }
  }

  &-icon {
    color: #838c9a;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;
  }
}
