@import './src/theme/theme';

.site-logo {
  margin-right: 10px;

  &-link {
    padding: 8px 0;
  }

  &-img {
    color: var(--bs-secondary);
    
  }
}
