.auction-editions {
  color: var(--bs-dark-blue);
  padding: 7.5px 18px;
  white-space: nowrap;

  &-available {
    color: #ee6220;
    white-space: nowrap;
  }
}
