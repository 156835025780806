@import 'nft-page/mixins';

.nft-page-details {
  margin-top: 42px;

  .nav-tabs {
    border: 0;
    border-bottom: 1px solid rgba(var(--bs-secondary-rgb), 0.4);
    
    .nav-link {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid transparent;
      color: var(--bs-icon-text);
      flex-grow: 1;
      font-size: 16px;
      font-weight: 500;
      padding: 11px 20px;

      &.active {
        border-bottom: 2px solid var(--bs-primary);
        color: var(--bs-primary);
      }
    }
  }

  .tab-content {
    @include nft-page-content-padding-x();
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
    padding-top: 24px;
  }
}
