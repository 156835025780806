@import '/src/theme/theme';

.search-results-item {
  align-items: center;


  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 10px 24px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }
  }


  &.selected {
    background-color: rgba(var(--bs-light-grey-rgb), 1);
  }

  &:hover {
    background-color: rgba(var(--bs-light-grey-rgb), 0.5);
  }

  &-link {
    height: 100%;
    left: 0;
    position: absolute;
    text-decoration: none;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &-icon {
    margin-right: 12px;
    width: 28px;

    @include media-breakpoint-up(md) {
      width: 32px;
    }
  }

  &-title {
    font-size: 14px;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  &-subtitle {
    color: var(--bs-silver);
    font-size: 10px;

    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
  }

  .verified-badge {
    margin-left: 0.3em;
  }
}
