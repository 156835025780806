@import 'src/theme/theme';

.card-with-header {
  border: $card-border-width solid var(--bs-medium-grey);
  border-radius: $card-border-radius;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  &-header {
    align-items: center;
    background-color: rgba(var(--bs-medium-grey-rgb), 0.3);
    display: flex;
    justify-content: space-between;
    line-height: 1.3;
    padding: 16px;
  }

  &-content {
    padding: 32px 16px 16px;

    > *:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}
