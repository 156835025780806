@import 'src/theme/theme';

.featured-page-header {
  background: url('/startup/assets/featured-header-bg.svg') center center no-repeat;
  background-position: top center;
  background-size: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;
  padding: 80px 0;
  position: relative;
  text-align: center;

  @include media-breakpoint-up(md) {
    background-size: 900px;
    min-height: 430px;
  }

  @include media-breakpoint-up(lg) {
    background-size: auto;
    min-height: 577px;
  }

  h1 {
    font-weight: 700;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
      font-size: 36px;    
    }

    @include media-breakpoint-up(lg) {
      font-size: 48px;    
    }
  }

  p {
    color: var(--bs-icon-text);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1.8em;

    @include media-breakpoint-up(md) {
      font-size: 18px;    
    }

    @include media-breakpoint-up(lg) {
      font-size: 24px;    
    }
  }

  .btn {
    min-width: 160px;

    @include media-breakpoint-up(md) {
      min-width: 240px;
    }
  }

  &-arrow {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
