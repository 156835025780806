@import 'src/theme/theme';

.hero {
  &-description {
    color: $navbar-light-active-color;
    font-size: 14px;
    margin-top: 20px;
    max-width: 553px;
    text-align: center;
  }
}
