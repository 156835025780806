@import './src/theme/theme';

.tab-header {
  margin-bottom: 32px;

  &-title {
    font-size: 24px;
    font-weight: 700;
    white-space: nowrap;


    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  &-icon {
    line-height: 2.2;
    width: 32px;
  }
}
