.social-link {
  margin-bottom: 16px;

  &-wrapper {
    align-items: center;
    background: rgba(136, 153, 179, 0.1);
    border-radius: 50%;
    color: var(--bs-body-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 40px;

    &:hover {
      color: var(--bs-primary);
    }

    &:focus-visible {
      background-color: #d1daf3;
      color: var(--bs-primary);
      outline: none;
    }
  }

  &-icon {
    height: 18px;
    width: 18px;
  }
}
