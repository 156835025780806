@import './src/theme/theme';

.asset-card {
  &-title {
    color: var(--bs-body-color);
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.small-on-mobile {
    .asset-card-title {
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }
}
