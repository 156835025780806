.followees-modal {
  &-content {
    max-height: 50vh;
  }

  .modal-dialog {
    max-width: 300px;
  }

  &-title {
    color: var(--bs-dark-blue);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: auto;
    width: 100%;

    &-inner {
      margin: 0 auto;
    }

    .infinite-scroll__load-trigger {
      top: -150px;
    }
  }

  .empty-container {
    text-align: center;
  }

  &-item {
    margin-bottom: 15px;
  }

  .loading-spinner {
    padding: 0 !important;

    &-inner {
      height: 80px;
      width: 80px;
    }

    &-text {
      font-size: 10px;
    }
  }
}
