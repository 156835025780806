@import './src/theme/theme';

.my-asset-card {
  &-price {
    //flex: 0 0 auto;

    &-inner {
      align-items: center;
      display: flex;
      padding: 6px 10px 6px 6px;
    }

    svg {
      height: auto;
      margin-right: 0.2em;
      width: 1.33em;
    }
  }

  &-prices {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 8px;
    text-align: right;

    @include media-breakpoint-up(sm) {
      align-items: flex-end;
      flex-direction: column;
      font-size: 18px;
      
    }
  }

  &-price-value {
    font-size: 1em;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 3px;
    white-space: nowrap;

    > * {
      vertical-align: middle;
    }
  }

  &-price-usd {
    font-size: 0.77em;
    margin-left: 5px;
    opacity: 0.7;
    white-space: nowrap;
  }

  &.small-on-mobile {
    .asset-card-price {
      @include media-breakpoint-down(sm) {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        min-height: 12px;
      }

      &-inner {
        @include media-breakpoint-down(sm) {
          padding: 2px 8px 2px 6px;
        }
      }

      svg {
        @include media-breakpoint-down(sm) {
          width: 25px;
        }
      }
    }

    .asset-card-price-value {
      @include media-breakpoint-down(sm) {
        font-size: 10px;
      }

      b {
        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
      }
    }

    .asset-card-price-usd {
      @include media-breakpoint-down(sm) {
        font-size: 8px;
      }
    }
  }
}
