@import './src/theme/theme';

.asset-card {
  &-price {
    @include gradient-box(50px);
    bottom: 8px;
    left: 8px;
    position: absolute;

    &-inner {
      align-items: center;
      display: flex;
      padding: 6px 10px 6px 6px;
    }

    svg {
      width: 32px;
    }
  }

  &-prices {
    line-height: 1;
    margin-left: 6px;
  }

  &-price-value {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 3px;
    white-space: nowrap;

    b {
      font-size: 14px;
    }
  }

  &-price-usd {
    font-size: 10px;
    opacity: 0.7;
    white-space: nowrap;
  }

  &.small-on-mobile {
    .asset-card-price {
      @include media-breakpoint-down(sm) {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        min-height: 12px;
      }

      &-inner {
        @include media-breakpoint-down(sm) {
          padding: 2px 8px 2px 6px;
        }
      }

      svg {
        @include media-breakpoint-down(sm) {
          width: 25px;
        }
      }
    }

    .asset-card-price-value {
      @include media-breakpoint-down(sm) {
        font-size: 10px;
      }

      b {
        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
      }
    }

    .asset-card-price-usd {
      @include media-breakpoint-down(sm) {
        font-size: 8px;
      }
    }
  }
}
