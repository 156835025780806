@import './src/theme/theme';

.collection-card-empty-list {
  align-items: center;
  background: linear-gradient(180deg, var(--bs-light-grey) 0%, rgba(var(--bs-light-grey-rgb), 0) 100%);
  border: 1px solid var(--bs-card-border-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
  
