.footer-powered-by {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
    
  &:hover {
    .footer-powered-by-text {
      color: var(--bs-white);
    }
    
    .footer-powered-by-icon {
      filter: brightness(100) invert(0);
    }
  }

  &-text {
    color: var(--bs-silver);
    line-height: 23px;
  }
}


