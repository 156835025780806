.my-asset-card {
  $self: &;

  &:hover {
    #{$self}-image img {
      transform: scale(1.05);
    }
  }

  &-image {
    background: linear-gradient(180deg, var(--bs-white-bg) 0%, var(--bs-light-grey) 100%);
    background-size: cover;
    border-radius: 5px;
    flex: 0 0 100px;
    height: 100px;
    overflow: hidden;
    pointer-events: none;
    position: relative;

    img {
      border-radius: 5px;
      height: 100%;
      left: 0;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      position: absolute;
      top: 0;
      transform: scale(1);
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
}
