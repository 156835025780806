.sidebar-avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  .user-image {
    border-width: 2px;
    filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.08));
  }
}
