.sidebar-menu {
  margin-top: 20px;

  &-counter {
    align-items: center;
    background-color: rgba(var(--bs-secondary-rgb), 0.1);
    border-radius: 19px;
    color: var(--bs-silver);
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-left: 16px;
    margin-right: -44px;
    min-height: 28px;
    min-width: 28px;
  }

  &-link {
    background-color: transparent;
    color: var(--bs-dark-blue);
    font-size: 14px;
    padding: 20px;
    text-decoration: none;
  }

  &-item {
    border-radius: 8px;

    &:hover {
      background-color: rgba(var(--bs-secondary-rgb), 0.1);

      a,
      .sidebar-menu-counter {
        color: var(--bs-primary);
      }
    }
  }

  li {
    &:last-child {
      border-bottom: 0 !important;
    }
  }
}
