@import 'src/theme/theme';

.subdomain-home-section {
  margin-bottom: 70px;
  overflow: hidden;
  padding-top: 30px;
    
  @include media-breakpoint-up(md) {
    margin-bottom: 170px;
  }
    
  &-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 50px;
    position: relative;
    text-align: center;
    
    @include media-breakpoint-up(md) {
      font-size: 40px;
    }

    span {
      bottom: 0;
      color: var(--bs-body-bg);
      font-size: 200%;
      left: 0;
      left: 50%;
      line-height: 1;
      opacity: 0.35;
      position: absolute;
      text-shadow: -1px -1px 0 var(--bs-primary), 1px -1px 0 var(--bs-primary), -1px 1px 0 var(--bs-primary), 1px 1px 0 var(--bs-primary);
      transform: translateX(-50%);
      width: 200%;
      z-index: -1;
    }
  }

  .subdomain-home-component:last-child & {
    margin-bottom: 25px;
  }
}
