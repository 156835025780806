@import './src/theme/theme';

.navbar-toggler {
  margin-right: 11px;
}

.logo {
  height: 38px;
  margin-right: 25px;
}


.navigation {
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
  min-height: $header-height-mobile;

  @include media-breakpoint-up(md) {
    min-height: $header-height;
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 0.65rem;
    padding-top: 0.65rem;
  }
}

.top-navigation-wrapper {
  background-color: var(--bs-top-nav-bg);
  min-width: 320px;
  position: absolute;
  top: 0;
  width: var(--viewport-width);

  @include media-breakpoint-up(lg) {
    position: fixed;
  }

  &-hidden {
    display: none;
  }

  &.not-found {
    z-index: 21;
  }

  &.subdomain {    
    .container-fluid {
      @include media-breakpoint-up(sm) {
        padding: 0 40px;
      }
    }
  }

  .subdomain-back-button {
    color: var(--bs-body-color);
    
    @include media-breakpoint-down(md) {
      padding: 14px;
      
      span {
        display: none;
      }
    }
  }
}
