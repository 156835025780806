@import 'src/theme/theme';

.search-toggle {
  background-color: var(--bs-light-grey-50);
  border: 2px solid var(--bs-light-grey-50);
  border-radius: 100px;
  color: #838c9a;
  cursor: pointer;
  height: 48px;
  margin-right: 12px;
  margin-top: 2px;
  min-width: 46px;
  outline: none;
  padding: 17px;

  @include media-breakpoint-down(md) {
    height: 40px;
    margin-right: 7px;
    min-width: 40px;
    padding: 11px;
  }

  &:hover {
    background-color: var(--bs-light-grey-hover);
    border: 2px solid var(--bs-light-grey-hover);
  }

  &:focus,
  &:active {    
    border: 2px solid var(--bs-light-grey);
  }
}
