@import '/src/theme/theme';

.nft-file-image {
  left: 50%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  z-index: 50;

  @include media-breakpoint-up(lg) {
    max-height: calc(100% - 90px);
    max-width: calc(100% - 190px);
  }

  &-wrapper {
    align-self: stretch;
    max-height: 100%;
    width: 100%;
  }

  img {
    border-radius: 6px;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }

  .nft-image-blur {
    filter: blur(25px);
    height: 100%;
    left: 50%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
  }
}

