@import './src/theme/theme';

.dropzone-content {
  border: 2px dashed var(--bs-light-grey);
  border-radius: 4px;
  max-width: 100%;
  min-height: 436px;
  overflow: hidden;
  padding: 15px;
  text-align: center;
  width: 100%;

  .dragging & {
    border-color: var(--bs-primary);
  }

  &:hover:not(.disabled) {
    border: 2px dashed var(--bs-primary);
    cursor: pointer;
  }

  &.crop-image {
    border: 0;
    min-height: 308px;
    padding: 0;

    &:hover {
      border: 0;
    }
  }

  &-error {
    border: 2px dashed var(--bs-danger);
    cursor: pointer;
    min-height: 436px;
  }

  
  /* stylelint-disable-next-line selector-class-pattern */
  .reactEasyCrop_CropArea {
    //border: 0;
    color: rgba(0, 0, 0, 0.7);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .reactEasyCrop_Container {
    border-radius: 4px;
  }
}

.dropzone-filetype {
  color: #608fd1;
  font-size: 16px;

  &-disabled {
    color: var(--bs-light-grey);
  }
}

.dropzone-close {
  width: 100%;
}
