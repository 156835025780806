.asset-tag {
  background-color: rgba(var(--bs-secondary-btn-rgb), 0.07);
  border-radius: 100px;
  color: var(--bs-icon-text);
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  padding: 8px 16px;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: rgba(var(--bs-secondary-btn-rgb), 0.14);
    color: var(--bs-icon-text);
  }
}
