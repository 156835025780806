@import 'src/theme/theme';

.holoride-action {
  &-inner {
    background-color: #180a26;
    border-radius: $card-border-radius;
    padding: 32px;
  }

  &-title {
    color: var(--bs-primary);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }

  &-description {
    color: var(--bs-silver);
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      max-width: 90%;
    }
  }

  .loading-spinner {
    padding: 0 !important;

    &-inner {
      height: 80px;
      width: 80px;
    }

    &-text {
      font-size: 10px;
    }
  }
}
