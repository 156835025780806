.nft-title {
  color: var(--bs-secondary);
  font-size: 32px;
  font-weight: bold;
  line-height: 1.36;
  margin-bottom: 8px;
  word-break: break-all;

  .explorer-link {
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 7px;
    vertical-align: middle;
  }
}
