@import './src/theme/theme';

.social-link-field {
  margin-bottom: 25px;

  @include media-breakpoint-up(md) {
    margin-bottom: 33px;
  }

  svg {
    color: var(--bs-dark-blue);
  }

  .form-label {
    flex: 0 0 25%;
    margin-bottom: 0;
  }

  .nerds-form-field {
    margin-bottom: 0;

    &-input {
      flex-grow: 1;
    }
  }
}
